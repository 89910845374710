body {
  .MuiButtonBase-root {
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: #002B54;
    text-transform: none;
    border-radius: 100px;
    padding: 10px 24px;
  }

  .MuiButtonBase-root.MuiButton-containedPrimary {
    background-color: #002B54;
    color: #ffffff;
  }

  .tc-bolder-text {
    font-weight: bold;
  }

  .tc-lighter-text {
    font-weight: 100;
  }

  .order-details-top-bar {
    background-color: #002B54;
  }

  .order-details-top-bar .order-details-tool-bar {
    padding: 0px;
  }

  .order-details-top-bar button {
    color: #ffffff;
    position: absolute;
    right: 0;
    z-index: 0;
  }

  .order-details-main-tabs .MuiTabs-scroller .MuiTabs-flexContainer {
    justify-content: space-around;
  }

  .order-details-main-tabs .MuiTabs-scroller .MuiTabs-flexContainer .MuiButtonBase-root{
    color: #605D62;
  }

  .order-details-main-tabs .MuiTabs-scroller .MuiTabs-flexContainer .Mui-selected {
    color: #002B54;
    font-weight: 600;
  }

  .order-details-main-tabs .MuiTabs-indicator {
    background-color: #002B54;
    border-radius: 5px 5px 0px 0px;
    height: 5px;
  }

  .order-details {
    display: flex;
    flex-direction: column;
    margin: 1% auto;
    padding: 20px 40px;
    width: 50%;
  }

  .order-details-item {
    color: #605D62;
    font-weight: 300;
  }

  .order-details .order-details-item-container {
    align-items: baseline;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
  }

  .order-details .order-details-item-container span {
    color: #002B54;
    font-weight: bold;
  }

  .deliveries-container {
    display: flex;
    flex-direction: column;
    margin: 1% auto;
    padding: 20px 40px;
    width: 50%;
  }
  .deliveries-container .deliveries-tabs {
    border: 1.5px solid #ccc;
    border-radius: 5px;
  }

  .deliveries-tabs .MuiTabs-scroller .MuiTabs-flexContainer {
    align-items: stretch;
    justify-content: space-around;
    width: 100%;
    flex-wrap: wrap;
  }

  .deliveries-tabs .deliveries-tab {
    flex-grow: 1;
    border-radius: 0px;
  }
  .deliveries-tabs .medium-tab {
    border-left: 1.5px solid #ccc;
    border-right: 1.5px solid #ccc;
  }

  .deliveries-tabs .Mui-selected {
    background-color: #002B54;
    color: #FFF;
    font-weight: bold;
  }

  .deliveries-tabs .MuiTabs-indicator {
    background-color: #002B54;
  }

  .deliveries-card {
    border-radius: 9px;
    cursor: pointer;
  }

  .MuiCardContent-root:last-child {
    padding: 15px;
  }

  .deliveries-card-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .deliveries-card-content-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  

  .deliveries-card-content-box-left {
    display: flex;
    align-items: center;
  }

  .leaflet-container {
    height: 50vh;
  }
}
